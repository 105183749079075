<template>
  <v-dialog v-model="showDialog" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px" scrollable>
    <component v-if="dynamicComponent !== null" :is="dynamicComponent" :key="component" v-bind="props"
      @close="closeDialog" />
  </v-dialog>
</template>

<script>
export default {
  name: 'MutationDialog',
  props: {
    component: {
      type: String,
      required: true
    },
    props: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data() {
    return {
      showDialog: true
    }
  },
  computed: {
    dynamicComponent() {
      return () => import(`@/${this.component}.vue`)
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit('updateGenericDialog', null)
      this.showDialog = false
    }
  }
}
</script>
